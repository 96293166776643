@import url('./assets/fonts/inter.css');

html {
  font-family: 'Inter', sans-serif !important;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif !important;
  }
}